<template>
  <div class="case-conent">
    <div class="hd">
      错题本
    </div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="table">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="list"
        >

          <el-table-column prop="sort" label="序号"  header-align="center" width="50"></el-table-column>
          <el-table-column prop="bookName" label="图书名称" header-align="center"></el-table-column>
          <el-table-column prop="folderName" label="课时名称" header-align="center"></el-table-column>
          <el-table-column prop="paperName" label="练习名称" header-align="center" width="200"></el-table-column>
          <el-table-column prop="subjectName" label="学科" header-align="center" width="80"></el-table-column>
          <el-table-column prop="errorcount" label="错题数" header-align="center" width="80"></el-table-column>
          <el-table-column prop="errQus" label="题号" header-align="center" width="180"></el-table-column>
          <el-table-column prop="crateTime" label="提交时间" header-align="center"></el-table-column>
          <el-table-column prop="" label="操作" header-align="center" width="120">
            <template #default="scope">
              <el-button @click="toWrongQuestion(scope)" type="primary" size="mini" plain>错题详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" >
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>

    </div>
  </div>
</template>


<script>

import {findScreenSubjectObj,queryWrongQuestionObj} from '@/api/wrongQuestion'
export default {
  name: '',
  data() {
    return {
      clickFlag:1,
      pageSize:null,
      pageCurrent:null,
      total:null,
      SubjectData: [],
      evaluation:{
        subject:''
      },
      list:[],
      listData:[
        {
          sort:1,
          orderNumber:'GR202109300001',
          stuName:'张三',
          rechargeType:'按需充值',
          originalPrice:2356,
          rechargeAmount:1000,
          discount:7,
          rechargeMode:'支付宝',
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],

    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.findScreenSubject()
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //查询筛选的学科列表
    findScreenSubject() {
      findScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SubjectData = res.data
      })
    },
    // 学科
    subjectChange($event){
      sessionStorage.setItem('subjectId',$event)
      this.listObj()
    },
    // 清除学科
    subjectClear() {
      this.evaluation.subject = ''
      sessionStorage.removeItem('subjectId');
      this.listObj()
    },


    //充值详情弹框
    rechargeObj(scope) {
      this.d1= scope.row
      this.rechargeDialog=true
    },
    closRechargeObj() {
      this.rechargeDialog=false
    },
    //购买详情弹框
    buyObj(scope) {
      this.d2= scope.row
      this.buyDialog=true
    },
    closbuyObj() {
      this.buyDialog=false
    },


    //查询列表
    listObj() {
      let params = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      queryWrongQuestionObj(params).then((res) => { // eslint-disable-line no-unused-vars
        //this.ListData = res.data.data.records
        this.list= res.data.data.data.records
        this.pageSize = res.data.data.data.size;
        this.currentPage = res.data.data.data.current;
        this.total = res.data.data.data.total
      })
    },
    //列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.listObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObj();
    },
    // 导出错题
    exportWrongQuestion() {

    },
    // 错题详情
    toWrongQuestion(scope) {
      this.$router.push({
        path: "/mainb/wrongQuestionDetails",
        query: {
          EducationCase:this.EducationCase,
          clickFlag:3,
          subjectId:scope.row.subjectId,
          paperId:scope.row.id,
          stu:1
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: relative;
    .back {
      position: absolute;
      justify-content: flex-start;
      left: 20px;
    }
  }

  .table-content {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
